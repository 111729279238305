import React, { useRef, useEffect } from "react";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from "@arcgis/core/Graphic";
import esriConfig from "@arcgis/core/config.js";
import Color from '@arcgis/core/Color';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import Fullscreen from '@arcgis/core/widgets/Fullscreen';
import Sketch from '@arcgis/core/widgets/Sketch';

import "./AGMap.css";
import { MapMarkerDTO } from "@shared/models/gmap-data";

import { JobRequestDTO } from "@shared/models/job-request";
import { makeConditionsStatus, makeMarkerIcon } from "../shared/common";
import { JR_STATUS_2_COLOR } from "@shared/constants/gmap-data";
import { ASSET_CONSTRUCTION_TYPE_LABELS } from "@shared/constants/asset";

const reactiveUtils = require('@arcgis/core/core/reactiveUtils');

// Props for AGMap component.  
// Note:
// BUGS:
// - Maximize then restore the map will make all graphics disappear.
export interface AGMapProps {
  orderId: number;                                                            // order id for editing a jobRequest.
  sites: MapMarkerDTO[];                                                      // list of markers to display.
  selectedSiteIds?: number[];                                                 // ids of selected assets
  center: {lat: number, lng: number};                                         // center of display area (probably not needed).
  bounds?: {latMin: number, latMax: number, lngMin: number, lngMax: number};  // Lat/Longitude Max/Min values for all markers.
  mapSize?: {width: number, height: number}
  leftTopPadding?: {left: number, top: number}
  isCustomer?: boolean;                                                       // Whether the login account has 'customer' role.
  allowSelection?: boolean;                                                   // Allow user to select assets
  // // Callbacks to bubble up changes that affect other component.
  onShowSiteDetails?: (marker: MapMarkerDTO) => void;                          // callback to load marker details on click.
  // onRefreshJobRequestInfo: (assetId: number, jobRequestId: number) => void; // callback to submit request to update a job request.
  // onChildCenterChanged: (childCenter) => void;
  // onBoundsChanged: (west: number, north: number, east: number, south: number) => void;
  // onRefresh: () => void;
  onSelectAssets?: (selectedAssetIds: Array<number>) => void;                  // callback to select assets
  onShow3DModel?: (assetId: number, mode: number) => void;                     // callback to show the 3D model.
}

// Show a map with markers for the sites.
// 
function AGMap({sites, center, bounds, leftTopPadding, allowSelection, onShowSiteDetails, onSelectAssets, onShow3DModel}: AGMapProps) {
  const agMapDiv = useRef<HTMLDivElement | null>(null);
  const view = useRef<MapView | undefined>(undefined);
  const graphicsLayer = useRef<GraphicsLayer | undefined>(undefined);
  const padding = leftTopPadding ?? {left: 10, top: 10};

  // Set color for site graphics based on jobReq status.
  const makeColorWithAlphaFromJrStatus = (jobRequests: Array<JobRequestDTO>, alpha: number): Color => {
    const baseColor = (jobRequests && jobRequests.length > 0) ? JR_STATUS_2_COLOR[jobRequests[0].status] : 'none';
    const rgb = (new Color(baseColor)).toRgb();
    return new Color(rgb.concat(alpha));
  }

  // Create an array of graphics to display the sites on the map
  const makeGraphicsFromSites = () => {
      const graphics: Array<any> = [];
      let graphic;
      sites.forEach((site)  => {
        graphic = new Graphic({
          geometry: { // Site location.
            type: 'point',
            latitude: site.lat,
            longitude: site.lng,
            spatialReference: { wkid: 102100 }
          },
          // symbol: { // default symbol
          //   type: "web-style", // autocasts as new WebStyleSymbol()
          //   styleName: "EsriIconsStyle",
          //   name: "Telecom"
          // },

          symbol: { // Site icon (monopole, latice, ...)
            type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
            path: makeMarkerIcon(site).path,
            color: makeColorWithAlphaFromJrStatus(site.jobRequests, 0.6),
            outline: {
              color: makeColorWithAlphaFromJrStatus(site.jobRequests, 0.6),
              width: 0.1
            },
            size: makeMarkerIcon(site).size,
          },
          attributes: { // Site information
            "assetID": site.assetId,
            "name": site.name,
            "type": ASSET_CONSTRUCTION_TYPE_LABELS[site.type ?? 0],
            "address": site.address,
            "conditions": makeConditionsStatus(site)
          },
          popupTemplate: allowSelection ? undefined : { // Template for the popup dialog
            title: site.name,
            content: "<p><b>Type:</b> {type}<br><b>Address:</b> {address}<br><b>Conditions:</b> {conditions}</p>",
            actions: [ 
              { // Cesium button.
                id: "view-cesium-3d",
                icon: "3d-glasses",
                title: "Cesium 3D"
              },
              { // MyX button.
                id: "view-myx-3d",
                icon: "select-visible",
                title: "MYX 3D"
              }
            ]
          }
      });
      graphics.push(graphic);
    });
    return graphics;
  }

  useEffect(() => {
    if (agMapDiv.current) {
      /**
       * Initialize application
       */

      esriConfig.apiKey = "AAPKd93f5553ae5b45ac9d0ddb00af00c091dWODH4TuabLxPD40xgFVZv3xmguOvaALh8rf_QILeGOkqG2Odfo2gymtbDlYiIbI";

      graphicsLayer.current = new GraphicsLayer({
        elevationInfo: {
          mode: 'on-the-ground',
        },
        // opacity: 0.6
      });
      
      const topographicMap = new Map({
        basemap: "arcgis/topographic", // basemap styles service
        layers: [graphicsLayer.current]
      });

      // Create a MapView
      view.current = new MapView({
        container: agMapDiv.current,
        map: topographicMap,
        center: [center.lng, center.lat], // Center and bounding rect passed from the parent component
        extent: {
          xmin: bounds?.lngMin,
          xmax: bounds?.lngMax,
          ymin: bounds?.latMin,
          ymax: bounds?.latMax
        },
      });

      // BasemapGallery -- allows user to select any kind of map
      const basemapGallery = new BasemapGallery({
        view: view.current
      });
      
      // BasemapGallery is quite big and may cover half the map, so put it inside an Expand item
      const expand = new Expand({
        view: view.current,
        content: basemapGallery
      });

      view.current.ui.add(
        expand,
        "bottom-left"
      );

      // Fullscreen control
      if (!allowSelection) {
        const fullscreen = new Fullscreen({
          view: view.current
        });
  
        view.current.ui.add(
          fullscreen,
          "top-right"
        );
      }
      
      if (allowSelection) {
        // Add Sketch toolbox -- allows the user to select assets by drawing on the map
        const sketch = new Sketch({
          view: view.current,
          layer: graphicsLayer.current,
          creationMode: "continuous",
          visibleElements: {
            createTools: {
              point: false,
              polyline: false,
              polygon: false,
              rectangle: false,
              circle: false
            },
            duplicateButton: false,
            settingsMenu: false,
            labelsToggle: false,
            tooltipsToggle: false,
            snappingControls: false,
            snappingControlsElements: {
              header: false,
              enabledToggle: false,
              selfEnabledToggle: false,
              featureEnabledToggle: false,
              layerList: true,
            },
            undoRedoMenu: false
          }
        });

        // Place this toolbox on the bottom-right corner
        view.current.ui.add(
          sketch,
          "bottom-right"
        );

        // Handle the 'update' event and record the selected assets
        sketch.on('update', (event) => {
          // Cancel moving assets
          if (event.toolEventInfo) {
            // console.log(`Sketch event ${JSON.stringify(event.toolEventInfo.type)}`)
            if (event.toolEventInfo.type.includes('move')) {
              sketch.cancel();
            }
          }

          // Record selected assets
          if (event.graphics.length > 0 && event.state === 'start') {
            // console.log(`Graphics to be selects: ${event.graphics.length}`);
            // event.graphics.forEach(x => {
            //   console.log(`${x.getAttribute('assetID')}`);
            // })
            if (onSelectAssets && event.graphics.length > 0) {
              const assetIds = event.graphics.map(x => x.getAttribute('assetID'));
              onSelectAssets(assetIds);
            }
          }
        });

        // Handle the 'delete' event. This event is sent *after* the deletion, so action cannot be canceled
        sketch.on('delete', (event) => {
          console.log(`Delete event. Tool ${JSON.stringify(event.tool)} - Type: ${JSON.stringify(event.type)}`);
          if (event.graphics.length > 0) {
            // So far, we cannot find a way to disable the "Delete feature" button
            // Kludge: add back the deleted asset
            event.graphics.forEach(x => {
              // console.log(`Add the deleted asset back ${x.getAttribute('assetID')}.`);
              graphicsLayer.current?.add(x);
            })
          }
        });        
      }

      // Handle the 'click' event and show the detail of the graphic
      view.current.on("click", (event) => {
        view.current?.hitTest(event, {include: graphicsLayer.current}).then((response) => {
          if (response.results.length) {
            const result = response.results[0];
            if (result.type === 'graphic') {
              const graphic = result.graphic;
              const assetID = graphic.getAttribute("assetID");
              // console.log(`Selected assetID ${assetID}`);
              const markers = sites.filter(x => x.assetId === assetID);
              if (markers.length > 0 && onShowSiteDetails) {
                onShowSiteDetails(markers[0]);
              }
            }
          }
        });
      });

      // Handle the 'trigger-action' event (when user clicks on one of the '3D Model' buttons on the popup)
      // and show the 3D model of the asset
      reactiveUtils.on(
        () => view.current?.popup,
        "trigger-action",
        (event) => {
          const graphic = view.current?.popup.selectedFeature;
          const assetID = graphic?.getAttribute("assetID");
          const markers = sites.filter(x => x.assetId === assetID);
          if (markers.length > 0 && onShow3DModel) {
            if (event.action.id == 'view-cesium-3d') {
              console.log('**** Show Cesium 3D Model for asset', markers[0].assetId);
              onShow3DModel(markers[0].assetId, 0);            
            }
            else if (event.action.id == 'view-myx-3d') {
              console.log('**** Show MYX 3D Model for asset', markers[0].assetId);
              onShow3DModel(markers[0].assetId, 1);
            }
          }
        })
    }
  }, [agMapDiv]);

  useEffect(() => {
    if (view.current) { // Add graphics
      // Refresh the assets
      if (graphicsLayer.current) {
        console.log(`Add graphics`);
        graphicsLayer.current.addMany(makeGraphicsFromSites());
      }
      // view.current.center = [center.lng, center.lat];
    }
    return (() => { // Remove old graphics
      if (graphicsLayer.current) {
        console.log(`Remove old graphics`);
        graphicsLayer.current.removeAll();
      }
    })
  }, [center]);

  useEffect(() => {
    if (view.current) {
      view.current.extent = {
        xmin: bounds?.lngMin,
        xmax: bounds?.lngMax,
        ymin: bounds?.latMin,
        ymax: bounds?.latMax
      };
    }
    return (() => { // Probably not needed.
    })
  }, [bounds]);

  return (
    <div 
      className="agMapDiv"
      style={{
        height: '99%', 
        width: '99%', 
        paddingLeft: padding.left, 
        paddingTop: padding.top
      }}
      ref={(ref) => {agMapDiv.current = ref}} />
  );
}

export default AGMap;
