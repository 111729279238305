import './polyfill';
import 'reflect-metadata';
// import 'react-hot-loader';

import React from 'react';
// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'; // v4
import { ThemeProvider } from "@mui/material/styles";                         // v5
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/browser';

// import theme from '@core/theme';
import themev5 from '@core/themev5';
import container from '@core/di';
import Config from '@core/config';
import App from './App';
import Notification from '@shared/components/Notification';
import Confirmation from '@shared/components/Confirmation';
import HTTPClient from '@core/http-client';
import AuthStore from '@core/stores/auth';
import history from '@shared/utils/history';
import { registerLicense } from '@syncfusion/ej2-base';
// registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFjUX5YcnVQTmdaWE0='); // Version 20
// registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW35dcXZQQ2NdVUF+XQ=='); // Version 23.2.7
// registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5feXVURGhZVkV2X0M='); // Version 24.1.46
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5WdUVhWXxbcXZdRmhf'); // Version 25.1.46
const config = container.get<Config>(Config.diToken);
const httpClient = container.get<HTTPClient>(HTTPClient.diToken);
const authStore = container.get<AuthStore>(AuthStore.diToken);

// Load configuration settings from src/core/config/config.json.
config.initialize();
const { apiURL } = config.get();

// CHECK: This logs (routing?) errors to a remote server that we do not access to.
if (IS_PRODUCTION) {
  const { errorTrackingAppID } = config.get();

  Sentry.init({ dsn: errorTrackingAppID });
}

// Config Axios with the base URL.
httpClient.initialize({ baseURL: apiURL });

authStore.initialize();

const appContainer = document.getElementById('root');
const root = createRoot(appContainer!);
root.render(
  (
    <Router history={history}>
      <ThemeProvider theme={themev5}>
        <CssBaseline />
        <Notification />
        <Confirmation />
        <App />
      </ThemeProvider>
    </Router>
  )
);
